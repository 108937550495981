import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ImageAlbum } from '@tencent/coral-design';

import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { href_profile as hrefProfile, ceGroupName } from 'components/href-helper';
import { Avatar } from 'components/user';
import { ImageDesktop } from 'components/comment-image';
import { CommentPropTypes } from './prop-types';
import { CommentParent } from './comment-parent';
import { postsTime, saveString } from 'components/util';

import './comment-desktop.less';
import { checkContentNeededFold } from 'components/util/content-fold';

/**
 * PC 端评论
 */
export class CommentDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 默认为 null，当有值的时候，表示显示某个图片
      show_image_album: null,
      needFold: false,
      foldBtnText: '',
      foldStatus: props.fold ? 'init-fold' : '',
    };

    this.bodyRef = createRef();
  }

  componentDidMount() {
    this.props.fold && this.checkFoldNedded();
  }

  checkFoldNedded() {
    checkContentNeededFold(
      this.props.content,
      this.bodyRef.current,
      20,
    ).then((needFold) => {
      if (needFold) {
        this.setState({
          needFold,
          foldBtnText: '全文',
          foldStatus: 'hide-fold',
        });
      } else {
        this.setState({
          foldStatus: '',
        });
      }
    });
  }

  switchFoldStatus() {
    const foldStates = this.state.foldStatus === 'up-fold'
      ? { foldBtnText: '全文', foldStatus: 'hide-fold' }
      : { foldBtnText: '收起', foldStatus: 'up-fold' };

    this.setState(foldStates);
  }

  /**
     * 评论的图片
     */
  renderImages() {
    const { props } = this;

    if (props.images.length <= 0) {
      return null;
    }

    const handleImagesClick = (i) => {
      this.setState({
        show_image_album: i,
      });
    };

    return (
      <div className="item_user_img">
        <ImageDesktop imgList={props.images} handleImagesClick={i => handleImagesClick(i)} />
      </div>
    );
  }

  /**
     * 引用评论
     */
  renderParent() {
    const { props } = this;
    if (!props.parent) {
      return null;
    }

    return <CommentParent {...props.parent} />;
  }

  /**
   * 引用新评论
   */
  renderNewParent() {
    const { props } = this;

    // TODO: 这里后端返回值有问题，之后沟通下，目前存在3种可能返回值 null, {id:0}, {id:1234, nick_name:xxx}
    if (!props.parent || !props.parent.nick_name) {
      return null;
    }

    return (
      <>
        回复
        <a
          href={hrefProfile(props.parent.user_id)}
          target="_blank"
          rel="noreferrer"
          className={props.parent.is_admin ? 'item_user_is_admin' : 'item_user_is_user'}
        >
          {saveString(props.parent.nick_name)}
        </a>：
      </>
    );
  }

  /**
     * 展开收起
     */
  renderFold() {
    if (!this.state.needFold) {
      return null;
    }

    return (
      <button className="item_user_fold-btn" onClick={() => this.switchFoldStatus()}>
        {this.state.foldBtnText}
      </button>
    );
  }

  render() {
    const { props, state } = this;

    const profileUrl = `/products/${props.productId}/profile/${props.user_id}`;
    return (
      <li className="comment-desktop comment_list_item">

        <div className="comment-side">
          <a
            href={profileUrl}
            target="_blank"
            className="comment__avatar-wrap" rel="noreferrer">
            <Avatar className="comment-avatar" src={props.avatar_url} />
          </a>
        </div>

        <div className="comment-main">

          <div className="item_user_font">
            <h5 className={classNames('item_user_name', { is_admin: props.is_admin })}>
              <a
                href={profileUrl}
                target="_blank" rel="noreferrer">
                {props.nick_name}
              </a>
            </h5>
            <div className="item_user_group_name">
              {ceGroupName(props.group_name, props.postname)}
            </div>
            <div className="item_user_time">
              {props.location} {postsTime(props.created_at_timestamp)}
            </div>
          </div>

          {this.renderNewParent()}
          <span
            ref={this.bodyRef}
            className={classNames('item_user_desc', state.foldStatus)}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML(props.content, props.productId, 'br', props.renderLink === 'on' || props.is_admin)}
          />

          {this.renderFold()}

          {this.renderImages()}

          {/* {this.renderParent()} */}

          <div className="comment-footer">

            {props.CommentFooter}

          </div>

          {state.show_image_album !== null && (
            <ImageAlbum
              imageList={props.images.map(img => img.original_url)}
              previewList={props.images.map(img => img.thumb_url)}
              showPreview={true}
              showIndex={state.show_image_album}
              onClose={event => this.setState({ show_image_album: null })}
            />
          )}

        </div>

      </li>
    );
  }
}

CommentDesktop.defaultProps = {
  avatar_url: '',
  content: '',
  created_at: '',
  id: '',
  images: [],
  location: '',
  nick_name: '',
  parent: null,
  time: '',
  user_id: '',
};


CommentDesktop.propTypes = {
  /* 评论操作组件 */
  CommentOperator: PropTypes.element,
  /**
     * 评论输入区域
     * @param {function} closeEnter 关闭
     */
  UserEnter: PropTypes.element,
  CommentFooter: PropTypes.element,
  ...CommentPropTypes,
  renderLink: PropTypes.string,
};

